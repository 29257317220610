/*Min and Max mixins*/
/* Progressive Enhancement for browsers not supporting grid except IE */
*, *::before, *::after {
  box-sizing: border-box;
}

/* IE10 and 11 Fallback to be remove when < 1%*/
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-right: -0.125rem;
    margin-left: -0.125rem;
  }
  .grid > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    min-height: 1px;
    padding-right: 0.125rem;
    padding-left: 0.125rem;
    margin-bottom: 0.25rem;
  }
  .grid.has-grid-gap-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .grid.has-grid-gap-0 > * {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .grid.has-grid-gap-1 {
    margin-right: -0.125rem;
    margin-left: -0.125rem;
  }
  .grid.has-grid-gap-1 > * {
    padding-right: 0.125rem;
    padding-left: 0.125rem;
    margin-bottom: 0.25rem;
  }
  .grid.has-grid-gap-2 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .grid.has-grid-gap-2 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-bottom: 0.5rem;
  }
  .grid.has-grid-gap-3 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .grid.has-grid-gap-3 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  .grid.has-grid-gap-4 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .grid.has-grid-gap-4 > * {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin-bottom: 1.25rem;
  }
  .grid.has-grid-gap-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .grid.has-grid-gap-5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 1.5rem;
  }
  .grid.has-grid-gap-6 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .grid.has-grid-gap-6 > * {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 3rem;
  }
  .grid.has-grid-gap-7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .grid.has-grid-gap-7 > * {
    padding-right: 3rem;
    padding-left: 3rem;
    margin-bottom: 6rem;
  }
  .grid.has-grid-gap-8 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .grid.has-grid-gap-8 > * {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
    margin-bottom: 9rem;
  }
  .grid.has-grid-gap-9 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .grid.has-grid-gap-9 > * {
    padding-right: 6rem;
    padding-left: 6rem;
    margin-bottom: 12rem;
  }
  .grid.has-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid.has-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid.has-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid.has-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid.has-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid.has-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .grid.has-cols-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .grid.has-cols-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .grid.has-cols-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .grid.has-cols-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .grid.has-cols-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .grid.has-cols-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (-ms-high-contrast: active) and (min-width: 20em), only screen and (-ms-high-contrast: none) and (min-width: 20em) {
  .grid.has-grid-gap-xs-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .grid.has-grid-gap-xs-0 > * {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .grid.has-grid-gap-xs-1 {
    margin-right: -0.125rem;
    margin-left: -0.125rem;
  }
  .grid.has-grid-gap-xs-1 > * {
    padding-right: 0.125rem;
    padding-left: 0.125rem;
    margin-bottom: 0.25rem;
  }
  .grid.has-grid-gap-xs-2 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .grid.has-grid-gap-xs-2 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-bottom: 0.5rem;
  }
  .grid.has-grid-gap-xs-3 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .grid.has-grid-gap-xs-3 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  .grid.has-grid-gap-xs-4 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .grid.has-grid-gap-xs-4 > * {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin-bottom: 1.25rem;
  }
  .grid.has-grid-gap-xs-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .grid.has-grid-gap-xs-5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 1.5rem;
  }
  .grid.has-grid-gap-xs-6 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .grid.has-grid-gap-xs-6 > * {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 3rem;
  }
  .grid.has-grid-gap-xs-7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .grid.has-grid-gap-xs-7 > * {
    padding-right: 3rem;
    padding-left: 3rem;
    margin-bottom: 6rem;
  }
  .grid.has-grid-gap-xs-8 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .grid.has-grid-gap-xs-8 > * {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
    margin-bottom: 9rem;
  }
  .grid.has-grid-gap-xs-9 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .grid.has-grid-gap-xs-9 > * {
    padding-right: 6rem;
    padding-left: 6rem;
    margin-bottom: 12rem;
  }
  .grid.has-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid.has-cols-xs-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid.has-cols-xs-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid.has-cols-xs-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid.has-cols-xs-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid.has-cols-xs-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .grid.has-cols-xs-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .grid.has-cols-xs-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .grid.has-cols-xs-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .grid.has-cols-xs-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .grid.has-cols-xs-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .grid.has-cols-xs-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (-ms-high-contrast: active) and (min-width: 37.5em), only screen and (-ms-high-contrast: none) and (min-width: 37.5em) {
  .grid.has-grid-gap-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .grid.has-grid-gap-sm-0 > * {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .grid.has-grid-gap-sm-1 {
    margin-right: -0.125rem;
    margin-left: -0.125rem;
  }
  .grid.has-grid-gap-sm-1 > * {
    padding-right: 0.125rem;
    padding-left: 0.125rem;
    margin-bottom: 0.25rem;
  }
  .grid.has-grid-gap-sm-2 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .grid.has-grid-gap-sm-2 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-bottom: 0.5rem;
  }
  .grid.has-grid-gap-sm-3 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .grid.has-grid-gap-sm-3 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  .grid.has-grid-gap-sm-4 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .grid.has-grid-gap-sm-4 > * {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin-bottom: 1.25rem;
  }
  .grid.has-grid-gap-sm-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .grid.has-grid-gap-sm-5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 1.5rem;
  }
  .grid.has-grid-gap-sm-6 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .grid.has-grid-gap-sm-6 > * {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 3rem;
  }
  .grid.has-grid-gap-sm-7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .grid.has-grid-gap-sm-7 > * {
    padding-right: 3rem;
    padding-left: 3rem;
    margin-bottom: 6rem;
  }
  .grid.has-grid-gap-sm-8 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .grid.has-grid-gap-sm-8 > * {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
    margin-bottom: 9rem;
  }
  .grid.has-grid-gap-sm-9 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .grid.has-grid-gap-sm-9 > * {
    padding-right: 6rem;
    padding-left: 6rem;
    margin-bottom: 12rem;
  }
  .grid.has-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid.has-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid.has-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid.has-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid.has-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid.has-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .grid.has-cols-sm-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .grid.has-cols-sm-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .grid.has-cols-sm-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .grid.has-cols-sm-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .grid.has-cols-sm-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .grid.has-cols-sm-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (-ms-high-contrast: active) and (min-width: 56.25em), only screen and (-ms-high-contrast: none) and (min-width: 56.25em) {
  .grid.has-grid-gap-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .grid.has-grid-gap-md-0 > * {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .grid.has-grid-gap-md-1 {
    margin-right: -0.125rem;
    margin-left: -0.125rem;
  }
  .grid.has-grid-gap-md-1 > * {
    padding-right: 0.125rem;
    padding-left: 0.125rem;
    margin-bottom: 0.25rem;
  }
  .grid.has-grid-gap-md-2 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .grid.has-grid-gap-md-2 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-bottom: 0.5rem;
  }
  .grid.has-grid-gap-md-3 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .grid.has-grid-gap-md-3 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  .grid.has-grid-gap-md-4 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .grid.has-grid-gap-md-4 > * {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin-bottom: 1.25rem;
  }
  .grid.has-grid-gap-md-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .grid.has-grid-gap-md-5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 1.5rem;
  }
  .grid.has-grid-gap-md-6 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .grid.has-grid-gap-md-6 > * {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 3rem;
  }
  .grid.has-grid-gap-md-7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .grid.has-grid-gap-md-7 > * {
    padding-right: 3rem;
    padding-left: 3rem;
    margin-bottom: 6rem;
  }
  .grid.has-grid-gap-md-8 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .grid.has-grid-gap-md-8 > * {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
    margin-bottom: 9rem;
  }
  .grid.has-grid-gap-md-9 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .grid.has-grid-gap-md-9 > * {
    padding-right: 6rem;
    padding-left: 6rem;
    margin-bottom: 12rem;
  }
  .grid.has-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid.has-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid.has-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid.has-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid.has-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid.has-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .grid.has-cols-md-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .grid.has-cols-md-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .grid.has-cols-md-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .grid.has-cols-md-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .grid.has-cols-md-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .grid.has-cols-md-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (-ms-high-contrast: active) and (min-width: 75em), only screen and (-ms-high-contrast: none) and (min-width: 75em) {
  .grid.has-grid-gap-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .grid.has-grid-gap-lg-0 > * {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .grid.has-grid-gap-lg-1 {
    margin-right: -0.125rem;
    margin-left: -0.125rem;
  }
  .grid.has-grid-gap-lg-1 > * {
    padding-right: 0.125rem;
    padding-left: 0.125rem;
    margin-bottom: 0.25rem;
  }
  .grid.has-grid-gap-lg-2 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .grid.has-grid-gap-lg-2 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-bottom: 0.5rem;
  }
  .grid.has-grid-gap-lg-3 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .grid.has-grid-gap-lg-3 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  .grid.has-grid-gap-lg-4 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .grid.has-grid-gap-lg-4 > * {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin-bottom: 1.25rem;
  }
  .grid.has-grid-gap-lg-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .grid.has-grid-gap-lg-5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 1.5rem;
  }
  .grid.has-grid-gap-lg-6 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .grid.has-grid-gap-lg-6 > * {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 3rem;
  }
  .grid.has-grid-gap-lg-7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .grid.has-grid-gap-lg-7 > * {
    padding-right: 3rem;
    padding-left: 3rem;
    margin-bottom: 6rem;
  }
  .grid.has-grid-gap-lg-8 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .grid.has-grid-gap-lg-8 > * {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
    margin-bottom: 9rem;
  }
  .grid.has-grid-gap-lg-9 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .grid.has-grid-gap-lg-9 > * {
    padding-right: 6rem;
    padding-left: 6rem;
    margin-bottom: 12rem;
  }
  .grid.has-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid.has-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid.has-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid.has-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid.has-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid.has-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .grid.has-cols-lg-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .grid.has-cols-lg-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .grid.has-cols-lg-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .grid.has-cols-lg-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .grid.has-cols-lg-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .grid.has-cols-lg-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (-ms-high-contrast: active) and (min-width: 112.5em), only screen and (-ms-high-contrast: none) and (min-width: 112.5em) {
  .grid.has-grid-gap-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .grid.has-grid-gap-xl-0 > * {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .grid.has-grid-gap-xl-1 {
    margin-right: -0.125rem;
    margin-left: -0.125rem;
  }
  .grid.has-grid-gap-xl-1 > * {
    padding-right: 0.125rem;
    padding-left: 0.125rem;
    margin-bottom: 0.25rem;
  }
  .grid.has-grid-gap-xl-2 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .grid.has-grid-gap-xl-2 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-bottom: 0.5rem;
  }
  .grid.has-grid-gap-xl-3 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .grid.has-grid-gap-xl-3 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  .grid.has-grid-gap-xl-4 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .grid.has-grid-gap-xl-4 > * {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin-bottom: 1.25rem;
  }
  .grid.has-grid-gap-xl-5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .grid.has-grid-gap-xl-5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 1.5rem;
  }
  .grid.has-grid-gap-xl-6 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .grid.has-grid-gap-xl-6 > * {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 3rem;
  }
  .grid.has-grid-gap-xl-7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .grid.has-grid-gap-xl-7 > * {
    padding-right: 3rem;
    padding-left: 3rem;
    margin-bottom: 6rem;
  }
  .grid.has-grid-gap-xl-8 {
    margin-right: -4.5rem;
    margin-left: -4.5rem;
  }
  .grid.has-grid-gap-xl-8 > * {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
    margin-bottom: 9rem;
  }
  .grid.has-grid-gap-xl-9 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .grid.has-grid-gap-xl-9 > * {
    padding-right: 6rem;
    padding-left: 6rem;
    margin-bottom: 12rem;
  }
  .grid.has-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid.has-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid.has-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid.has-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid.has-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid.has-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .grid.has-cols-xl-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .grid.has-cols-xl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .grid.has-cols-xl-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .grid.has-cols-xl-10 > * {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .grid.has-cols-xl-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .grid.has-cols-xl-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}
